import styled from 'styled-components';
import tw from 'twin.macro';

export const Testimonials = styled.div`
  ${tw`max-w-screen-sm lg:max-w-unset mx-auto w-full px-0 sm:px-16 mb-4`};
`;

export const Testimonial = styled.div`
  ${tw`flex flex-col items-center text-center mt-32 px-40 justify-center`};
`;

export const Image = styled.figure`
  ${tw`w-48 h-48 mx-auto border border-teal-400 rounded-full`};

  img {
    ${tw`border-4 border-white rounded-full`};
  }
`;

export const Title = styled.h3`
  ${tw`font-semibold my-4`};
`;
