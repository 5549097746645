import { useEffect, DependencyList } from 'react';
import _debounce from 'lodash/debounce';

const useResizeEffect = (callback: () => void, dependencyList: DependencyList = []) => {
  useEffect(() => {
    callback();
    const resizeHandler = _debounce(callback, 200);
    window.addEventListener('resize', resizeHandler);

    return () => {
      resizeHandler.flush();
      window.removeEventListener('resize', resizeHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencyList);
};

export default useResizeEffect;
