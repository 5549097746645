// extracted by mini-css-extract-plugin
export var bla = "Values-module--bla--iPNsG";
export var button = "Values-module--button--A7WYa";
export var dimond = "Values-module--dimond--rbKUW";
export var middle = "Values-module--middle--VL265";
export var middleDimond = "Values-module--middleDimond--y03xo";
export var ribbon = "Values-module--ribbon--laVf1";
export var rightDimond = "Values-module--rightDimond--KazNF";
export var rightSide = "Values-module--rightSide--czEKu";
export var valueImageContainer = "Values-module--valueImageContainer--XvzFy";
export var valueImageTextOne = "Values-module--valueImageTextOne--1KrJZ";
export var valueImageTextThree = "Values-module--valueImageTextThree--uSTmi";
export var valueImageTextTwo = "Values-module--valueImageTextTwo--ZWaug";