import React, { useCallback } from 'react';
import c from 'classnames';
import * as styles from './styles.module.scss';

type HeadlineProps = {
  variant?: 'headline-1' | 'headline-2' | 'headline-3' | 'headline-4' | 'headline-5' | 'headline-6';
  centered?: boolean;
  spaced?: boolean;
  color?: 'blue' | 'white' | 'dark' | 'black';
  additionalClass?: string;
  style?: any;
};

const Headline: React.FC<HeadlineProps> = ({
  children,
  spaced,
  variant = 'headline-1',
  centered = false,
  color = 'blue',
  additionalClass,
  style = {},
}) => {
  const HeadlineTag = useCallback(
    ({ ...props }) => React.createElement(`h${variant.replace('headline-', '')}`, props, children),
    [children, variant]
  );

  return (
    <HeadlineTag
      className={c(
        variant,
        { 'text-center': centered },
        { [styles.spaced]: spaced },
        { 'text-dark': color === 'dark' },
        // { 'text-blue-dark': color === 'blue' },
        { 'text-white': color === 'white' },
        { 'text-black': color === 'black' },
        additionalClass
      )}
      style={{ ...style }}
    >
      {children}
    </HeadlineTag>
  );
};

export default Headline;
