import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img, { FluidObject } from 'gatsby-image';
import Loadable from '@loadable/component';

import Container from 'components/ui/Container';
import TitleSection from 'components/ui/TitleSection';
import FormatHtml from 'components/utils/FormatHtml';

import { SectionTitle, ImageSharpFluid } from 'helpers/definitions';

import * as Styled from './styles';
import useBreakpoint from '../../helpers/useBreakpoints';
import { useI18next } from 'gatsby-plugin-react-i18next';
import Headline from '../Headline';

const Carousel = Loadable(() => import('components/ui/Carousel'));

interface Testimonial {
  node: {
    id: string;
    html: string;
    frontmatter: {
      title: string;
      lawyerNote: string;
    };
  };
}

const ValueBlocks: React.FC = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
    query {
      allMarkdownRemark(filter: { frontmatter: { category: { eq: "testimonials" } } }) {
        edges {
          node {
            id
            html
            frontmatter {
              title
              lawyerNote
            }
          }
        }
      }
    }
  `);

  const testimonials: Testimonial[] = allMarkdownRemark.edges;
  const { t } = useI18next();
  const bps = useBreakpoint();

  return (
    <Container section>
      <TitleSection subtitle={t('valuesSectionSubtitle')} center className="mt-16" large />
      <Styled.Testimonials>
        <Carousel propSettings={{ slidesToShow: bps.isDesktop ? 2 : 1 }} blackIcons>
          {testimonials.map((item, idx) => {
            const {
              id,
              html,
              frontmatter: { lawyerNote, title },
            } = item.node;

            return (
              <Styled.Testimonial key={id}>
                {/*<TitleSection title={t(blocks.title[idx])} subtitle={t(blocks.subTitle[idx])} center />*/}
                {/*<Styled.Image>*/}
                {/*  <Img fluid={cover.childImageSharp.fluid} alt="testimonial" />*/}
                {/*</Styled.Image>*/}
                <Styled.Title>{title}</Styled.Title>
                <FormatHtml content={html} />
                <Styled.Title style={{ marginTop: '15px', textAlign: 'justify' }}>{lawyerNote}</Styled.Title>
              </Styled.Testimonial>
            );
          })}
        </Carousel>
      </Styled.Testimonials>
    </Container>
  );
};

export default ValueBlocks;
