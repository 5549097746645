import { useState } from 'react';
import getTheme from './getTheme';
import useResizeEffect from './useResizeEffect';
import isBrowser from '../utils/isBrowser';

export default function useBreakpoint(noResize?: boolean): {
  isTablet: boolean;
  isDesktop: boolean;
} {
  const { md, lg } = getTheme<Dictionary<string>>('screens');
  const tabletWidth = Number(md.replace('px', ''));
  const desktopWidth = Number(lg.replace('px', ''));

  const [breakpoints, setBreakpoints] = useState(() => ({
    isTablet: isBrowser() && window.innerWidth >= tabletWidth,
    isDesktop: isBrowser() && window.innerWidth >= desktopWidth,
  }));

  useResizeEffect(() => {
    if (!noResize) {
      setBreakpoints({
        isTablet: isBrowser() && window.innerWidth >= tabletWidth,
        isDesktop: isBrowser() && window.innerWidth >= desktopWidth,
      });
    }
  });

  return breakpoints;
}
