import React from 'react';
import c from 'classnames';
import Img, { FluidObject } from 'gatsby-image';
import Loadable from '@loadable/component';
import * as styles from './Values.module.scss';

import * as Styled from './styles';
import Container from '../ui/Container';
import CopyText from '../CopyText';
import { useI18next } from 'gatsby-plugin-react-i18next';
// import FullContainer from '../ui/FullContainer';
// import Image1 from '../../assets/images/homePage/devotat.jpg';
// import Image2 from '../../assets/images/homePage/rezultate1.jpg';
// import Image3 from '../../assets/images/homePage/rezultate2.jpg';
// import Image4 from '../../assets/images/homePage/solutii.jpg';
// import Image5 from '../../assets/images/homePage/valorileNoastre.jpg';

const Carousel = Loadable(() => import('components/ui/Carousel'));

type Props = { images: Record<string, { childImageSharp: { fluid: FluidObject } }> };

const Values: React.FC<Props> = ({
  images: {
    homeImageDevotat,
    homeImageText,
    homeImageRezultate1,
    homeImageRezultate2,
    homeImageSolutii,
    homeImageValorileNoastre,
  },
}) => {
  const { t } = useI18next();

  const images = [
    homeImageDevotat,
    // homeImageText,
    homeImageRezultate1,
    // homeImageRezultate2,
    homeImageSolutii,
    // homeImageValorileNoastre,
  ];

  const imageTextDevotat = t('imageTextDevotat');
  const imageTextRezultate = t('imageTextRezultate');
  const imageTextSolutii = t('imageTextSolutii');

  const texts = [imageTextRezultate, imageTextDevotat, imageTextSolutii];

  const imageStyle = {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  };
  const classes = [styles.valueImageTextOne, styles.valueImageTextTwo, styles.valueImageTextThree];

  return (
    <Container className="giga mt-0 pt-0">
      {/*<Styled.Values>*/}
      <Carousel>
        {images.map((image, idx) => {
          return (
            // <Styled.Value key={idx}>
            //   <Styled.Image>
            <div className={styles.valueImageContainer} key={idx}>
              <a className={c(idx === 2 && styles.rightSide, idx === 1 && styles.middle)}>
                <span
                  className={c(
                    styles.button,
                    styles.dimond,
                    idx === 2 && styles.rightDimond,
                    idx === 1 && styles.middleDimond
                  )}
                >
                  {texts[idx]}
                </span>
              </a>
              {/*<div className={classes[idx]}>*/}
              {/*  <CopyText variant="copy-3" color="inherit" additionalClass="relative">*/}
              {/*    <span className={c(styles.bla, styles.ribbon)}>*/}
              {/*      */}
              {/*    </span>*/}
              {/*  </CopyText>*/}
              {/*</div>*/}
              <Img key={idx} fluid={image.childImageSharp.fluid} alt="value" style={imageStyle} />
            </div>
            // </Styled.Image>
            // </Styled.Value>
          );
        })}
        {/*<img src={Image1} />*/}
        {/*<img src={Image2} />*/}
        {/*<img src={Image3} />*/}
        {/*<img src={Image4} />*/}
        {/*<img src={Image5} />*/}
      </Carousel>
      {/*</Styled.Values>*/}
    </Container>
  );
};

export default Values;
