const { rem } = require('./utils');

module.exports = {
  theme: {
    // fontFamily: {
    //   sans: ['Open Sans', 'Arial', 'monospace'],
    // },
    fontSize: {
      ...rem(10),
      ...rem(12),
      ...rem(14),
      ...rem(16),
      ...rem(18),
      ...rem(20),
      ...rem(22),
      ...rem(24),
      ...rem(28),
      ...rem(30),
      ...rem(40),
    },
    lineHeight: {
      1: 1,
      ...rem(12),
      ...rem(16),
      ...rem(18),
      ...rem(20),
      ...rem(22),
      ...rem(24),
      ...rem(28),
      ...rem(30),
      ...rem(32),
      ...rem(38),
      ...rem(40),
      ...rem(46),
      ...rem(48),
      initial: 'initial',
      inherit: 'inherit',
      normal: 'normal',
    },
    letterSpacing: {
      normal: `${0.3 / 16}rem`,
    },
    fontWeight: {
      normal: '400',
      medium: '600',
      bold: '700',
    },
  },
};
