const plugin = require('tailwindcss/plugin');
// const colors = require('./src/theme/colors');
const typography = require('./src/theme/typography');
const spacing = require('./src/theme/spacing');

const headerHeight = '52px';
const headerSearchHeight = '50px';
const pagePlusHeaderHeight = `calc(100vh - ${headerHeight})`;
// const mobilePageHeaderHeight = '300px';
// const tabletPageHeaderHeight = '250px';
const panelMaxHeight = '1000px';
const chartMinHeight = '300px';

//
// const typography = {
//   theme: {
//     fontFamily: {
//       sans: ['FF DIN Web Pro', 'Arial', 'monospace'],
//     },
//     fontSize: {
//       ...rem(12),
//       ...rem(14),
//       ...rem(16),
//       ...rem(18),
//       ...rem(20),
//       ...rem(24),
//       ...rem(30),
//       ...rem(40),
//     },
//     lineHeight: {
//       1: 1,
//       ...rem(16),
//       ...rem(18),
//       ...rem(20),
//       ...rem(22),
//       ...rem(24),
//       ...rem(26),
//       ...rem(28),
//       ...rem(30),
//       ...rem(38),
//       ...rem(46),
//       ...rem(48),
//     },
//     letterSpacing: {
//       normal: `${0.3 / 16}rem`,
//     },
//     fontWeight: {
//       normal: '400',
//       medium: '500',
//       bold: '700',
//     },
//   },
//   plugins: theme => ({
//     '.copy-1': {
//       fontSize: theme('fontSize.18'),
//       lineHeight: theme('lineHeight.22'),
//       fontWeight: theme('fontWeight.normal'),
//       '@screen desktop': {
//         fontSize: theme('fontSize.20'),
//         lineHeight: '24px',
//       },
//     },
//     '.copy-2': {
//       fontSize: '16px',
//       lineHeight: theme('lineHeight.20'),
//       fontWeight: theme('fontWeight.normal'),
//       '@screen desktop': {
//         fontSize: theme('fontSize.18'),
//         lineHeight: theme('lineHeight.22'),
//       },
//     },
//     '.copy-3': {
//       fontSize: '16px',
//       lineHeight: theme('lineHeight.20'),
//       fontWeight: theme('fontWeight.medium'),
//       '@screen desktop': {
//         fontSize: theme('fontSize.18'),
//         lineHeight: theme('lineHeight.22'),
//       },
//     },
//     '.copy-4': {
//       fontSize: theme('fontSize.14'),
//       lineHeight: theme('lineHeight.18'),
//       fontWeight: theme('fontWeight.normal'),
//       '@screen desktop': {
//         fontSize: '16px',
//         lineHeight: theme('lineHeight.22'),
//       },
//     },
//     '.copy-5': {
//       fontSize: theme('fontSize.14'),
//       lineHeight: theme('lineHeight.18'),
//       fontWeight: theme('fontWeight.medium'),
//       '@screen desktop': {
//         fontSize: '16px',
//         lineHeight: theme('lineHeight.20'),
//       },
//     },
//     '.copy-6': {
//       fontSize: theme('fontSize.12'),
//       lineHeight: theme('lineHeight.16'),
//       fontWeight: theme('fontWeight.normal'),
//       '@screen desktop': {
//         fontSize: theme('fontSize.14'),
//         lineHeight: theme('lineHeight.20'),
//       },
//     },
//     '.copy-7': {
//       fontSize: theme('fontSize.12'),
//       lineHeight: theme('lineHeight.16'),
//       fontWeight: theme('fontWeight.normal'),
//       '@screen desktop': {
//         fontSize: theme('fontSize.14'),
//         lineHeight: theme('lineHeight.16'),
//         fontWeight: theme('fontWeight.medium'),
//       },
//     },
//     '.copy-8': {
//       fontSize: theme('fontSize.12'),
//       lineHeight: theme('lineHeight.16'),
//       fontWeight: theme('fontWeight.medium'),
//     },
//     '.copy-9': {
//       fontSize: theme('fontSize.12'),
//       lineHeight: theme('lineHeight.16'),
//       fontWeight: theme('fontWeight.normal'),
//     },
//     '.copy-10': {
//       fontSize: '16px',
//       lineHeight: theme('lineHeight.22'),
//       fontWeight: theme('fontWeight.normal'),
//       '@screen desktop': {
//         fontSize: theme('fontSize.18'),
//         lineHeight: theme('lineHeight.30'),
//       },
//     },
//   }),
// };
//
// const colors = {
//   transparent: 'transparent',
//   black: '#000',
//   white: '#fff',
//   'dark-blue': '#00265d',
//   blue: '#003972',
//   'warm-blue': '#809cb9',
//   'cold-blue': '#cad4e0',
//   'light-blue': '#F0F3F7',
//   pink: {
//     20: '#eacbd9',
//     DEFAULT: '#960045',
//   },
//   red: '#d61414',
//   green: '#018913',
//   'light-green': '#00B919',
//   yellow: '#ffb549',
//   'dark-yellow': '#e49626',
//   grey: '#a8a8a8',
//   'light-grey': '#f1f1f1',
// };
//
// const fill = {
//   'dark-blue': colors['dark-blue'],
//   pink: colors.pink,
// };
//
// module.exports = {
//   // mode: 'jit',
//   // purge: ['./src/**/*.{tsx,scss}'],
//   theme: {
//     ...typography.theme,
//     colors,
//     fill,
//     spacing: {
//       px: '1px',
//       0: '0',
//       ...rem(5),
//       ...rem(10),
//       ...rem(15),
//       ...rem(20),
//       ...rem(25),
//       ...rem(30),
//       ...rem(35),
//       ...rem(40),
//       ...rem(45),
//       ...rem(50),
//       ...rem(55),
//       ...rem(60),
//       ...rem(65),
//       ...rem(70),
//       ...rem(75),
//       ...rem(80),
//       ...rem(85),
//       ...rem(90),
//       ...rem(95),
//       ...rem(100),
//       ...rem(110),
//       ...rem(150),
//       ...rem(200),
//       ...rem(250),
//     },
//     borderRadius: {
//       none: '0',
//       sm: '4px',
//       lg: '8px',
//       xl: '16px',
//       full: '9999px',
//     },
//     boxShadow: {
//       none: 'none',
//       top: '0 0 10px 2px rgba(0, 0, 0, 0.08)',
//       sm: '0 2px 4px 0 rgba(0, 0, 0, 0.07)',
//       md: '0 6px 20px 2px rgba(0, 0, 0, 0.07)',
//       lg: '0 10px 25px 2px rgba(0, 0, 0, 0.2)',
//     },
//     borderWidth: {
//       DEFAULT: '1px',
//       0: '0',
//       1: '1px',
//       2: '2px',
//       4: '4px',
//       8: '8px',
//     },
//     opacity: {
//       0: '0',
//       10: '0.1',
//       20: '0.2',
//       30: '0.3',
//       40: '0.4',
//       50: '0.5',
//       60: '0.6',
//       70: '0.7',
//       80: '0.8',
//       90: '0.9',
//       100: '1',
//     },
//     rotate: {
//       '-180': '-180deg',
//       '-90': '-90deg',
//       '-45': '-45deg',
//       0: '0',
//       45: '45deg',
//       90: '90deg',
//       180: '180deg',
//     },
//     extend: {
//       screens: {
//         phoneLandscape: '480px',
//         tablet: '768px',
//         desktop: '1025px',
//         large: '1260px',
//         xl: '1440px',
//         xxl: '1920px',
//       },
//       inset: {
//         '1/5': '20%',
//         '2/5': '40%',
//         '3/5': '60%',
//         '4/5': '80%',
//         '1/6': '16.666667%',
//         '2/6': '33.333333%',
//         '3/6': '50%',
//         '4/6': '66.666667%',
//         '5/6': '83.333333%',
//         '1/12': '8.333333%',
//         '2/12': '16.666667%',
//         '3/12': '25%',
//         '4/12': '33.333333%',
//         '5/12': '41.666667%',
//         '6/12': '50%',
//         '7/12': '58.333333%',
//         '8/12': '66.666667%',
//         '9/12': '75%',
//         '10/12': '83.333333%',
//         '11/12': '91.666667%',
//       },
//       maxHeight: {
//         0: '0',
//       },
//       maxWidth: {
//         '6xs': '1rem',
//         '5xs': '2rem',
//         '4xs': '4rem',
//         '3xs': '8rem',
//         '2xs': '12rem',
//         '1xs': '16rem',
//         '4xl': '56rem',
//       },
//       width: {
//         180: '11.25rem', // 180px
//         275: '17.1875rem', // 275px
//       },
//       zIndex: {
//         '-1': '-1',
//         header: '100',
//         offCanvas: '200',
//         modal: '300',
//         modalHeader: '350',
//         infoBar: '400',
//       },
//       animation: {
//         slideIn: 'slideIn 300ms ease',
//       },
//       keyframes: {
//         slideIn: {
//           '0%': { transform: 'translateX(100vw)' },
//           '100%': { transform: 'translateX(0)' },
//         },
//       },
//       transitionProperty: {
//         background: 'background',
//         height: 'height',
//         width: 'width',
//         spacing: 'margin, padding',
//         margin: 'margin',
//         padding: 'padding',
//         'font-weight': 'font-weight',
//       },
//     },
//   },
//   variants: {},
//   plugins: [
//   ],
// };

const rem = num => ({ [num]: `${num / 16}rem` });

module.exports = {
  purge: ["./src/**/*.{js,jsx,ts,tsx}"],
  future: {
    purgeLayersByDefault: true,
  },
  theme: {
    extend: {
      ...typography,
      inset: {
        '1/5': '20%',
        '2/5': '40%',
        '3/5': '60%',
        '4/5': '80%',
        '1/6': '16.666667%',
        '2/6': '33.333333%',
        '3/6': '50%',
        '4/6': '66.666667%',
        '5/6': '83.333333%',
        '1/12': '8.333333%',
        '2/12': '16.666667%',
        '3/12': '25%',
        '4/12': '33.333333%',
        '5/12': '41.666667%',
        '6/12': '50%',
        '7/12': '58.333333%',
        '8/12': '66.666667%',
        '9/12': '75%',
        '10/12': '83.333333%',
        '11/12': '91.666667%',
      },
      height: {
        header: headerHeight,
        pagePlusHeader: pagePlusHeaderHeight,
        '10/12': '83.333333%',
      },
      minHeight: {
        header: headerHeight,
        headerSearch: headerSearchHeight,
        pagePlusHeader: pagePlusHeaderHeight,
        unset: 'unset',
        ...spacing,
        '1/4': '25%',
        '1/2': '50%',
        '3/4': '75%',
        chart: chartMinHeight,
        'geo-page-element': '600px',
      },
      maxHeight: {
        unset: 'unset',
        0: '0',
        200: '200px',
        header: headerHeight,
        panel: panelMaxHeight,
        pagePlusHeader: pagePlusHeaderHeight,
        'geo-page-element': "calc(100vh - theme('spacing.header') - 230px);",
      },
      minWidth: {
        ...spacing,
        unset: 'unset',
        '1xs': '1rem',
        '2xs': '2rem',
        '3xs': '4rem',
        '4xs': '6rem',
        '5xs': '8rem',
        '6xs': '12rem',
        '7xs': '16rem',
        '8xs': '20rem',
        '9xs': '24rem',
        '1xl': '30rem',
        '2xl': '36rem',
        '3xl': '42rem',
        '4xl': '50rem',
        'fit-content': 'fit-content',
      },
      maxWidth: {
        '1xs': '1rem',
        '2xs': '2rem',
        '3xs': '4rem',
        '4xs': '6rem',
        '5xs': '8rem',
        '6xs': '12rem',
        '7xs': '16rem',
        '8xs': '20rem',
        '9xs': '24rem',
        '1xl': '30rem',
        '2xl': '36rem',
        '3xl': '42rem',
        '4xl': '50rem',
        unset: 'unset',
        screen: '100vw',
        full: '100%',
      },
      colors: {
        initial: 'initial',
        inherit: 'inherit',
        'current-color': 'currentColor',
        transparent: 'transparent',
        backdrop: 'rgba(1,30,66,0.3)',
        black: '#000000',
        dark: '#1A1A1A',
        gray: {
          1: '#333333',
          2: '#4F4F4F',
          3: '#828282',
          disabled: '#DADADA',
          4: '#BDBDBD',
          5: '#E0E0E0',
          light: '#E0E0E0',
          6: '#F2F2F2',
        },
        // bran blue -> pink
        'bn-gradient': 'linear-gradient(180deg, #A23182 0%, #47A5E5 100%);',
        white: '#FFFFFF',
        'white-light': 'rgba(255, 255, 255, 0.7)',
        'white-disabled': 'rgba(255, 255, 255, 0.5)',
        background: {
          white: '#F9FBFD',
        },
        blue: {
          gray: {
            base: '#8497AB',
            DEFAULT: '#8497AB',
            1: '#AFBECC',
            2: '#C3CED9',
            3: '#D5DDE5',
            4: '#DFE5EB',
            5: '#E6EBF0',
            6: '#EDF1F5',
            background: '#F7F9FB',
          },
          dark: '#011E42',
          n: '#012A5C',
          base: '#265B99',
          DEFAULT: '#265B99',
          ocean: '#2182C3',
          brand: '#47A5E5',
          light: '#8FC5EA',
          info: '#2C8CDF',
          infoLight: '#E8F4FD',
        },
        pink: '#A23182',
        red: {
          base: '#F03738',
          error: '#F03738',
          active: '#C50F10',
          dark: '#A20202',
          light: '#FEEBEB',
        },
        green: {
          base: '#29B028',
          success: '#29B028',
          light: '#ECF9EB',
        },
        yellow: {
          base: '#F3BB1C',
          warning: '#F3BB1C',
          light: '#FEF8E8',
        },
        teal: {
          300: '#81e6d9',
          400: '#4fd1c5',
          // 300: 'rgb(228,139,17)',
          // 400: 'rgb(222,74,30)',
        },
        // indigo: {
        //   400: 'black',
        //   500: 'black',
        //   600: 'black',
        //   900: 'black',
        // },
      },
      spacing: {
        ...spacing,
        px: '1px',
        0: '0',
        ...rem(2),
        ...rem(3),
        ...rem(4),
        ...rem(5),
        ...rem(10),
        ...rem(15),
        ...rem(20),
        ...rem(25),
        ...rem(30),
        ...rem(35),
        ...rem(40),
        ...rem(45),
        ...rem(50),
        ...rem(55),
        ...rem(60),
        ...rem(65),
        ...rem(70),
        ...rem(75),
        ...rem(80),
        ...rem(85),
        ...rem(90),
        ...rem(95),
        ...rem(100),
        ...rem(110),
        ...rem(150),
        ...rem(200),
        ...rem(250),
        ...rem(300),
      },
    },
  },
  variants: {},
  plugins: [
    plugin(({ addComponents, theme }) => {
      addComponents({
        '.stacked-component': {
          margin: '0 auto',
          padding: `0 ${theme('spacing.15')}`,
          width: '100%',
          maxWidth: theme('screens.large'),
        },
        '.flex-container': {
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 0%',
          padding: `${theme('spacing.12')}`,
          height: 'auto',
          overflow: 'auto',
          width: '100%',
          position: 'relative',
          '@screen md': {
            padding: `${theme('spacing.16')}`,
          },
        },
        '.c-h-flex': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '.page-container': {
          width: '100%',
          display: 'flex',
          minHeight: pagePlusHeaderHeight,
          flexDirection: 'column',
          flex: '1 1 0%',
        },
        '.c-h-v-flex': {
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        '.mobile-only': {
          display: 'flex',
          '@screen md': {
            display: 'none',
          },
        },
        '.no-mobile': {
          display: 'none',
          '@screen md': {
            display: 'flex',
          },
        },
      });
    }),
  ],
};

