const { rem } = require('./utils');

module.exports = {
  px: '1px',
  0: '0',
  ...rem(2),
  ...rem(4),
  ...rem(6),
  ...rem(8),
  ...rem(10),
  ...rem(12),
  ...rem(14),
  ...rem(16),
  ...rem(20),
  ...rem(24),
  ...rem(28),
  ...rem(30),
  ...rem(32),
  ...rem(36),
  ...rem(40),
  ...rem(44),
  ...rem(48),
  ...rem(50),
  ...rem(52),
  ...rem(56),
  ...rem(60),
  ...rem(64),
  ...rem(72),
  ...rem(80),
  ...rem(88),
  ...rem(90),
  ...rem(96),
  ...rem(100),
  ...rem(104),
  ...rem(120),
  ...rem(150),
  ...rem(200),
  ...rem(208),
};
