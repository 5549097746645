// extracted by mini-css-extract-plugin
export var eighthHeadline = "styles-module--eighthHeadline--sgMZZ";
export var fifthHeadline = "styles-module--fifthHeadline--HXWbH";
export var firstHeadline = "styles-module--firstHeadline--zc4sh";
export var fourthHeadline = "styles-module--fourthHeadline--4ktNp";
export var headlineContainer = "styles-module--headlineContainer--IfP8M";
export var image = "styles-module--image--APDLI";
export var secondHeadline = "styles-module--secondHeadline--+JmIU";
export var seventhHeadline = "styles-module--seventhHeadline--xr9Qn";
export var sixthHeadline = "styles-module--sixthHeadline--BOVie";
export var thirdHeadline = "styles-module--thirdHeadline--NR71W";
export var title = "styles-module--title--skvS8";
export var valueImage = "styles-module--valueImage--g9EK3";
export var valueTextDesktop = "styles-module--valueTextDesktop--KPZ5J";
export var valueTextMobile = "styles-module--valueTextMobile--AnhSZ";