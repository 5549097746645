import React from 'react';
import Img, { FluidObject } from 'gatsby-image';
import c from 'classnames';

import Container from '../ui/Container';
import { useI18next } from 'gatsby-plugin-react-i18next';
import HorizontalTitleSeparator from '../HorizontalTitleSeparator/HorizontalTitleSeparator';
import CopyText from '../CopyText';
import * as styles from './styles.module.scss';
import Headline from '../Headline';

type Props = { images: Record<string, { childImageSharp: { fluid: FluidObject } }> };

const PlusSvg = (
  <div
    style={{
      display: 'inline-flex',
      width: 20,
      height: 20,
      minWidth: 20,
      minHeight: 20,
      borderRadius: '100%',
      border: '1px solid black',
      marginRight: '10px',
    }}
  >
    <svg xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 24 24">
      <path
        fill-rule="evenodd"
        d="M12 4c-.78 0-1.412.632-1.412 1.412v5.176H5.412a1.412 1.412 0 000 2.824h5.176v5.176a1.412 1.412 0 002.824 0v-5.176h5.176a1.412 1.412 0 000-2.824h-5.176V5.412C13.412 4.632 12.78 4 12 4z"
        clip-rule="evenodd"
      />
    </svg>
  </div>
);

const HomeAbout: React.FC<Props> = ({
  images: { homeImagePhoto, homeImageBusola2 },
}) => {
  const { t } = useI18next();

  const imageStyle = {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  };

  return (
    <Container className="mt-12 pt-12 md:px-16 px-4">
      <HorizontalTitleSeparator title={t('hometitle')} additionalClass="mb-24" />
      <div className="flex w-full flex-col md:flex-row items-center px-32 mb-32">
        <div className="flex flex-col items-center text-justify md:mr-32 italic">
          <CopyText variant="copy-2" firstLetter>{t('hometext1')}</CopyText>
          <CopyText variant="copy-2" additionalClass="mt-16" firstLetter>
            {t('hometext2')}
          </CopyText>
          <CopyText variant="copy-2" additionalClass="mt-16" firstLetter>
            {t('hometext3')}
          </CopyText>
          <CopyText variant="copy-2" additionalClass="mt-16 ml-auto">
            {t('hometext4')}
          </CopyText>
        </div>
        <div className={styles.image}>
          <Img fluid={homeImagePhoto.childImageSharp.fluid} alt="value" style={imageStyle} />
        </div>
      </div>
      <div className="w-full flex flex-col relative">
        <div className={styles.valueImage}>
          <Img fluid={homeImageBusola2.childImageSharp.fluid} alt="value" style={imageStyle} />
        </div>
        <div className={styles.valueTextMobile}>
          <Headline variant="headline-5" color="black" additionalClass={styles.title}>
            Ne ghidăm după următoarele valori:
          </Headline>
          <div className={styles.headlineContainer}>
            <CopyText variant="copy-4" color="black" additionalClass={styles.firstHeadline}>
              {PlusSvg} Discreție și profesionalism
            </CopyText>
            <CopyText variant="copy-4" color="black" additionalClass={styles.thirdHeadline}>
              {PlusSvg} Perseverență și promptitudine
            </CopyText>
            <CopyText variant="copy-4" color="black" additionalClass={styles.fifthHeadline}>
              {PlusSvg} Devotament și creativitate
            </CopyText>
            <CopyText variant="copy-4" color="black" additionalClass={styles.seventhHeadline}>
              {PlusSvg} Simplitate și transparență
            </CopyText>
          </div>
        </div>
        <div className={c(styles.valueTextDesktop)}>
          <Headline spaced variant="headline-1" color="black" additionalClass={styles.title}>
            Ne ghidăm după următoarele valori:
          </Headline>
          <div className={styles.headlineContainer}>
            <Headline spaced variant="headline-5" color="black" additionalClass={styles.firstHeadline}>
              {PlusSvg} Discreție și profesionalism
            </Headline>
            <CopyText spaced variant="copy-2" color="black" additionalClass={styles.secondHeadline}>
              Virtuți care guvernează relația cu fiecare client.
            </CopyText>
            <Headline spaced variant="headline-5" color="black" additionalClass={styles.thirdHeadline}>
              {PlusSvg} Perseverență și promptitudine
            </Headline>
            <CopyText spaced variant="copy-2" color="black" additionalClass={styles.fourthHeadline}>
              În soluționarea fiecărui proiect / caz.
            </CopyText>
            <Headline spaced variant="headline-5" color="black" additionalClass={styles.fifthHeadline}>
              {PlusSvg} Devotament și creativitate
            </Headline>
            <CopyText spaced variant="copy-2" color="black" additionalClass={styles.sixthHeadline}>
              O constantă în exercitarea profesiei de avocat.
            </CopyText>

            <Headline spaced variant="headline-5" color="black" additionalClass={styles.seventhHeadline}>
              {PlusSvg} Simplitate și transparență
            </Headline>
            <CopyText spaced variant="copy-2" color="black" additionalClass={styles.eighthHeadline}>
              Cu ocazia fiecărei interacțiuni.
            </CopyText>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default HomeAbout;
