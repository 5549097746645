import React from 'react';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
// import HeroBanner from 'components/HeroBanner';
// import Services from 'components/Services';
import Values from 'components/Values';
// import Testimonials from 'components/Testimonials';
import { Provider } from 'react-redux'
import ValueBlocks from 'components/ValueBlocks';
import { graphql } from 'gatsby';
import { FluidObject } from 'gatsby-image';
import { getStore } from '../infrastructure/store';
import HomeAbout from '../components/HomeAbout';

type Props = {
  data: Record<string, { childImageSharp: { fluid: FluidObject } }>;
};

const IndexPage: React.FC<Props> = props => {
  return (
    <Provider store={getStore()}>
      <Layout id="home">
        <SEO title="Home" />
        <Values images={props.data} />
        {/*<HeroBanner />*/}
        <HomeAbout images={props.data} />
        {/*<Services />*/}
        <ValueBlocks />
      </Layout>
    </Provider>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    homeImageDevotat: file(relativePath: { eq: "homePage/devotat4.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeImageText: file(relativePath: { eq: "homePage/text2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeImageRezultate1: file(relativePath: { eq: "homePage/inlocuim-problemele-cu-solutii.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeImageRezultate2: file(relativePath: { eq: "homePage/rezultate2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeImageSolutii: file(relativePath: { eq: "homePage/solutii.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeImagePhoto: file(relativePath: { eq: "homePage/home-photo.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeImageValorileNoastre: file(relativePath: { eq: "homePage/valorileNoastre.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeImageBusola1Original: file(relativePath: { eq: "busola-1.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeImageBusola1: file(relativePath: { eq: "busola-1.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeImageBusola2: file(relativePath: { eq: "busola-2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    homeImageBusola2Original: file(relativePath: { eq: "busola-2-orig.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
